<template>
	<div>
		<div>
			<b-tabs
				vertical
				pills
				nav-class="nav-left"
				nav-wrapper-class="col-md-2 col-12"
				content-class="col-12 col-md-10 mt-1 mt-md-0"
				lazy
			>
				<b-tab title="Economic Activity">
					<economic-activity />
				</b-tab>
				<b-tab title="Beneficiaries">
					<beneficiaries />
				</b-tab>
				<b-tab title="Services">
					<services />
				</b-tab>
				<b-tab title="Ngos">
					<ngos />
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import Beneficiaries from './Beneficiaries.vue';
import EconomicActivity from './EconomicActivity.vue';
import Services from './Services.vue';
import Ngos from './Ngos.vue';

export default {
	name: 'BaselineReports',

	components: {
		BTabs,
		BTab,
		Beneficiaries,
		EconomicActivity,
		Services,
		Ngos,
	},
};
</script>

<style lang="scss" scoped></style>
