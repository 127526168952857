<template>
	<div>
		<base-table :headers="headers" :items="items" table-top paginate excel-file-name="services">
			<template #action>
				<div class="flex-fill d-flex justify-content-between align-items-center">
					<v-select
						id="technician"
						v-model="donorId"
						class="flex-fill mx-75"
						:options="getDonors"
						:reduce="(o) => o.id"
						placeholder="Select a donor"
					/>
					<b-button variant="primary" @click="loadData">Filter</b-button>
				</div>
			</template>
		</base-table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import vSelect from 'vue-select';
import api from '@/utils/api';

export default {
	name: 'Services',

	components: { BaseTable, BButton, vSelect },

	data: () => ({
		headers: [
			{ key: 'city', label: 'المحافضة' },
			{ key: 'class_room_count', label: 'عدد الفصول' },
			{ key: 'health_facilities', label: 'هل يوجد مرفق صحي (عدد الاجابات بنعم)' },
			{ key: 'projects', label: 'عدد المشاريع' },
		],

		items: [],

		donors: [],
		donorId: '',
	}),

	computed: {
		...mapGetters('attributes', ['getDonors']),
	},

	created() {
		this.loadData();
	},

	methods: {
		async loadData() {
			const params = new URLSearchParams({ donorId: this.donorId ?? '' });
			const query = params.get('donorId') ? `?${params}` : '';

			const { data } = await api.call({
				path: `/reports/baseline-services${query}`,
				method: 'GET',
			});

			this.items = data;
		},
	},
};
</script>

<style lang="scss" scoped></style>
