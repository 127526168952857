import { render, staticRenderFns } from "./Beneficiaries.vue?vue&type=template&id=b1a4d23a&scoped=true&"
import script from "./Beneficiaries.vue?vue&type=script&lang=js&"
export * from "./Beneficiaries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1a4d23a",
  null
  
)

export default component.exports